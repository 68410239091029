/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;




@font-face {
  font-family: 'Raleway';
  src: url('https://fonts.gstatic.com/s/raleway/v19/1Ptug8zYS_SKggPNyC0IT5lu.woff2') format('woff2'),
        url('https://fonts.gstatic.com/s/raleway/v19/1Ptug8zYS_SKggPNyC0IT5lu.woff') format('woff');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
 }


 
 body {
  font-family: "Raleway", sans-serif;
 }

p, h1, h2{
    margin: 0;
}
h1, ol{
    margin: 0;
}

* {
    outline: none !important;
  }

  .react-multiple-carousel__arrow{
    background-color: transparent !important;

  }

  .react-multiple-carousel__arrow::before{
    color: #D8D8D8 !important;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
}
.react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}

/* .react-multi-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
} */




@keyframes animateLoaderPath {
  0% {
    stroke-dashoffset: 0px;
  }

  100% {
    stroke-dashoffset: -100px;
  }
}

@keyframes bounceAnimation {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.spinnersv1 {
  fill: transparent;
  pointer-events: none;
  stroke: #fffcfc;
  stroke-width: 2px;
  animation-duration: 300ms;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.spinnersv2 {
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
  stroke-linejoin: miter;
  animation-duration: 550ms;
  stroke: #f1b942;
  stroke-dasharray: 300;
  animation-iteration-count: infinite;
  animation-name: animateLoaderPath;
  fill: transparent !important;
  pointer-events: none;
  stroke-width: 6px;
}

.swiper-button-next, .swiper-button-prev{
color: #D8D8D8 !important; 

}

.swiper-button-next{
  right: 5px;
}


.swiper-button-prev{
  left: 5px;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 280% !important;
}

.swiper-button-nxt:after,  .swiper-button-pre:after {
  font-size: 100% !important;
  color: #312522 !important;
}
.swiper{
  z-index: 0 !important;
}


.fc-daygrid-more-link{
  font-weight: 700 !important;
}

@media only screen and (max-width: 768px) {
  .p-multiselect-panel {
    max-width: 309px;
    /* left: 50%; */
    /* transform-origin: center top; */
    /* transform: translateX(-50%); */
  }
}

@media only screen and (max-width: 767px) {
  .fc-toolbar-chunk:first-of-type {
      /* Your mobile-specific styles here */
      flex-wrap: wrap;
      gap: 20px;
      justify-content: space-between;
      width: 100%;
  }
  .fc-toolbar-chunk:first-of-type > .fc-button-group:last-of-type{
    justify-content: space-between;
    width: 100%;
  }

  .fc-daygrid-day-bottom{
    font-size: 10px !important;
  }
}


.css-1ji7omv-MuiBackdrop-root{
  background-color: rgba(0, 0, 0, 0.2) !important;
}

@media screen and (max-width: 480px) {
  .swiper-button-next  .swiper-button-prev{
    top: 60% !important;
  }
  
 

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 180% !important;

  }

  .modal-content{
    top: -50px !important;
  }
 
}
 .swiper-horizontal{
  width: 95%;
  margin: auto;
}

.syncbtn{

  font-family: "Open Sans";
}

.a:hover{
  text-decoration: none !important;
  color:unset !important;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
}


a:hover {
  text-decoration: none !important;
  /* color: unset !important; */
}

i:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.css-79ws1d-MuiModal-root{
  z-index: 50 !important;
}

/* css for iframe spotify */

.frame  .TitleAndSubtitle_title__Nwyku{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: initial !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.otp-input-container:focus-within {
  filter: none; /* Remove the blur effect */
 }

 /* .p-calendar{
  max-width: 180px;
  background-color: #fafaf8 !important;
  padding: 10px 10px;
  border-radius: 32px;
 } */

 .p-inputtext{
  background-color:  #fafaf8 !important;
 }

 
 .p-datepicker{
  /* width: 250px !important;
  min-width: 250px !important;
  z-index: 99999 !important;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); /* Drop shadow */
  /* padding: 10px;  */
 }

 

 .accountDate  .p-calendar{
  max-width: 100% !important;
  width: 100% !important;
  background-color: #fafaf8 !important;
  padding: 10px 10px;
  border-radius: 32px;
  position:  relative !important;
 }

 .p-inputwrapper{
  border-radius: 10px !important;
  padding-top: 11px !important;
  background-color: #fafaf8 !important;
  margin-top: 10px !important;
  /* padding-right: 10px; */
  padding-bottom: 11px !important;
  padding-left: 5px !important;
 }

/* Custom Checkbox */
.custom-checkbox input[type="checkbox"]:checked + label span {
  background-color: #FFAD00; /* Checked color */
  border-color: #FFAD00; /* Checked border color */
 }
 
 .custom-checkbox input[type="checkbox"]:focus + label span {
  box-shadow: 0 0 0 3px rgba(255, 173, 0, 0.5); /* Focus ring color */
 }

 .p-dropdown-panel{
  z-index: 99999 !important;
  background: white !important;
  padding: 20px;
 }

 .containerOTP{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
 }

 /* .p-dialog-mask{
  background: #000 !important;
  opacity: 0.4 !important;
 } */

 .p-dialog-mask {
  background-color: #00000085 !important;
 }

 .p-dialog{
  background: white;
 }


 .css-187mznn-MuiSlider-root{
  color: #312522 !important;
 }

 .css-exkjcx{
  color: #312522 !important;

 }


 @keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animation-marquee {
  animation: marquee 5s linear infinite;
}

.custom-carousel .p-carousel-next-icon {
  font-size: 2em; /* Adjust the size as needed */
  color: #D8D8D8 !important;
}

.custom-carousel .p-carousel-prev-icon {
  font-size: 2em; /* Adjust the size as needed */
  color: #D8D8D8 !important;
}

.p-datepicker-other-month{
  color: rgb(168, 158, 158) !important;
}

.p-disabled{
  color: rgb(168, 158, 158) !important;

}

.p-overlaypanel{
  z-index: 0 !important;
}

.fc-button{
  background-color: #F1B942 !important;
  border: none !important;
}

.fc .fc-button:focus {
  box-shadow: none !important
}

.fc .fc-button-primary:focus {
  background-color: #F1B942 !important;
  box-shadow: none !important;
  color: white !important;
}

.fc-timeGridWeek-button{
  background-color: white !important;
  color: black !important;
  border: 1px solid #F3F4F6 !important;
}

.fc-timeGridDay-button{
  background-color: white !important;
  color: black !important;
  border: 1px solid #F3F4F6 !important;
}

.fc-dayGridMonth-button{
  background-color: white !important;
  color: black !important;
  border: 1px solid #F3F4F6 !important;
}

.fc-toolbar-title{
  font-size: 16px !important;
}

.p-dialog-header{
  padding: 0px !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
  background-color: #F1B942 !important;
}

.p-checkbox .p-checkbox-box{
  border: #f1b942 !important;
}



@media (max-width: 600px) {
  .fc-toolbar-title {
    text-align: center !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .fc-header-toolbar{
    display: block !important;
  }
  .fc-toolbar-chunk{
    display: flex;
    justify-content: center;
  }
  .fc-view-harness{
    height: 450px !important;
  }
}

/* .p-multiselect-panel{
  padding: 15px;
  background-color: white !important;
  --tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  
} */

/* .p-connected-overlay-enter-done{
  top: 75% !important;
} */

.p-confirm-popup{
  top: 500px !important;

}

.p-confirm-popup-footer{
  display: flex !important;
  justify-content: end !important;
  gap: 20px !important;
}

/* .p-datepicker{
  width: 270px !important;
  height: 300px !important;
  top: 100px !important;
}

.p-datepicker-calendar-container{
  height: 180px !important;
} */

.p-multiselect-label{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-multiselect-header{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-datepicker{
  z-index: 999999 !important;
}

.p-dialog-header{
  display: none !important;
}

.p-dialog-content{
  padding: 0px !important;
}


.svg-hover-color {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(107%) contrast(101%);
  transition: filter 0.1s ease;
}

.svg-hover-color-black {
  filter: invert(100%);
  transition: filter 0.1s ease;
}

.group:hover .svg-hover-color {
  filter: brightness(0) saturate(100%) invert(75%) sepia(53%) saturate(497%) hue-rotate(356deg) brightness(106%) contrast(101%);
}


.group:hover .svg-hover-color-black-normal {
  filter: brightness(0) saturate(100%) invert(75%) sepia(53%) saturate(497%) hue-rotate(356deg) brightness(106%) contrast(101%);
}


/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
    -webkit-text-fill-color: #ffffff !important; 
} */

.fc-popover-header {
  background-color: #D9D9D9 !important;
  color: #616463 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  padding: 10px !important;
}

.fc-popover-body {
  background-color: #373737 !important;
  color: #4E81E7 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  padding: 10px !important;
}

.fc-popover{
  border: none !important;
  border-radius: 10px !important;

}

.p-multiselect-filter-icon{

  padding-left: 10px;
  padding-right: 10px !important;
  right: 1px;
  top: 9px;
  height: 30px;
  width: 40px;
  background-color: #f8f8f8 !important;
  border-radius: 20px;


}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.p-multiselect{
  box-shadow: none !important;

}

.p-multiselect-items-wrapper{
  background-color: #E9E9E9 !important;
}

.p-multiselect-header{
  background-color: #E9E9E9 !important;
}

.p-inputtext{
  height: 30px !important;
}

@media (max-width: 767px) {
  .p-multiselect-panel {
    left: 17px !important;
  }
}

.p-dialog-content{
  background: none !important
}

.loginSignUp{
  -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
    -webkit-text-fill-color: #ffffff !important; 
}




@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-15deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(15deg); }
  100% { transform: rotate(0deg); }
}

.bell-shake {
  animation: shake 0.5s;
}

.goog-te-gadget-simple{
  display: flex !important ;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 10px !important;
}

.VIpgJd-ZVi9od-xl07Ob-lTBxed{
  display: flex;
}

.fc-button-primary{
  border-radius: 50px !important;
}

.MuiButton-textSizeMedium{
  padding: 0px !important;
  margin-top: 5px !important;
}

.p-dialog {
  max-height: 100%;
}


.custom-calendar .p-datepicker {
    width: 400px !important; /* Control the width of the opened calendar */
    height: auto; /* Adjust height as needed */
}


.dark-mode-picker input {
  color: white; /* Placeholder text color */
}

.dark-mode-picker .ant-picker-placeholder {
  color: gray; /* Placeholder color in dark mode */
}

.dark-mode-picker .ant-picker-suffix {
  color: white; /* Icon color in dark mode */
}

/* Optional: Hover styles in dark mode */
.dark-mode-picker:hover {
  background-color: black;
  color: white;
}
.dark-mode-picker{
  background-color: #000000 !important;
  color: white !important;
}

.react-datepicker-wrapper{
  width: 100%;
}

/* date picker dark style */

/* .dark-mode-picker .ant-picker-header {
  background-color: black;
  color: white;
}
.dark-mode-picker .ant-picker-dropdown {
  background-color: black !important;
  color: white; 
}

.dark-mode-picker .ant-picker-prev-icon, .dark-mode-picker .ant-picker-next-icon {
  color: white;
}

.dark-mode-picker .ant-picker-cell-disabled .ant-picker-cell-inner {
  color: #555;
}

.dark-mode-picker .ant-picker-today-btn {
  background-color: black;
  color: white;
}

.dark-mode-picker .ant-picker-cell-inner:hover {
  background-color: #444;
}

.dark-mode-picker .ant-picker-cell-inner {
  color: white;
} */


@media (min-width: 1280px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin-bottom: 70px !important;
  }
}

.custom-carousel .no-spacing .p-carousel-item {
  margin: 0 !important;
}

.p-carousel-items-container{
  margin: 0 !important;
}

.fc-button-group{
  gap: 10px !important;
}

.no-scroll {
  overflow: hidden;
}







